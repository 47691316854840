.ant-table-tbody tr.table-row-light > td {
  background-color: #f9fafb;
}

.ant-table-tbody tr.new-cpt-highlight > td {
  background-color: #FEF2E1;
}
.ant-table-tbody tr.cpt-drawer-table.ant-table-row:hover > td {
  background-color: #fff !important;
}

.ant-table-row.contracts {
  min-height: 62px;
}
.ant-table-row.quality-measures, .ant-table-row.quality-measures-expanded {
  height: 80px;
}
.ant-table-row.contracts td > div, .ant-table-row.quality-measures td > div, .ant-table-row.quality-measures-expanded td > div, .ant-table-row.quality-measures-report td > div {
  margin-left: 8px !important;
}

tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
  padding: 4px;
}
.ant-pagination.wrapper a {
  font-family: 'Inter_18pt-Regular';   
}

tr.ant-table-expanded-row > td {
  background-color: #F6F7F8;
  border-bottom: 0.5px solid #D0D6E1;
}

tr.ant-table-expanded-row:hover > td {
  background-color: #F6F7F8;
  border-bottom: 0.5px solid #D0D6E1;
}

tr.quality-measures-expanded > td {
  border-bottom: 0.5px solid #D0D6E1;
  border-top: 0.5px solid #D0D6E1;
}

tr.quality-measures > td {
  border-bottom: 0.5px solid #F6F7F8;
  border-top: 0.5px solid #F6F7F8;
}

.quality-measures-table .ant-table-thead > tr > th {
  border-width: 0.5px;
  border-color: #F6F7F8;
}