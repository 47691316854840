.custom-antd-input-box.ant-input,
.custom-antd-input-box.ant-picker,
.custom-antd-input-box.ant-input:hover,
.custom-antd-input-box.ant-picker:hover,
.custom-antd-input-box.ant-input:focus,
.custom-antd-input-box.ant-picker-focused {
  box-shadow: none !important;
  border: 0.5px solid #8a94a8 !important;
  border-radius: 4px !important;
  color:#3A485F;
}

.custom-antd-input-box.ant-picker.ant-picker-disabled {
  background-color: #f5f5f5 !important;
  color:#3A485F;
}

.custom-antd-input-box.ant-input::placeholder,
.custom-antd-input-box.ant-picker input::placeholder,
.custom-select-box .ant-select-selector .ant-select-selection-placeholder {
  color: #8a94a8 !important;
  font-size: 14 !important;
  font-weight: 400 !important;
}

.custom-select-box .ant-select-selector,
.custom-select-box .ant-select-selector:hover,
.custom-select-box.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff !important;
  min-height: 32px !important;
  max-height: 32px !important;
  height: 32px !important;
  border-radius: 4px !important;
  border: 0.5px solid #8a94a8 !important;
  color:#3A485F;
}

.care-team-select.custom-select-box .ant-select-selector,
.care-team-select.custom-select-box .ant-select-selector:hover,
.care-team-select.custom-select-box.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  border: 0.5px solid #D0D6E1 !important;
}

.custom-select-box-dropdown.ant-select-dropdown {
  padding: 8px;
  border-radius: 8px !important;
  border: 0.5px solid #e9ecf1;
  box-shadow: 0px 12px 60px -15px #0000002f;
}

.custom-select-box-dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option {
  border-radius: 4px !important;
  padding: 5px !important;
  padding-left: 8px !important;
}

.hide {
  display: none;
}
